import React, {useRef} from 'react';
import {useInterval, useKeyPress} from './hooks';
import {swipeAndFetchIfNeeded} from './actions';
import {connect} from 'react-redux';

import {api, options} from './config';

import './App.css';
import {QRCodeSVG} from "qrcode.react";
import {getBooleanConfigParam, getConfigParam} from "./utils";

function App(props) {
    const galleryRef = useRef(null);
    const photos = props.photos.slice(props.mediasPerSlide * (props.slide - 1), props.mediasPerSlide * props.slide);
    useKeyPress((event) => {
        if (event.keyCode === options.fsKeyCode) {
            toggleFullScreen(galleryRef.current)
        }
    });
    useInterval(() => {
        props.dispatch(swipeAndFetchIfNeeded(props.uuid, props.token));
    }, options.slideInterval)

    let removeWatermark = getBooleanConfigParam("removeWatermark");
    let itemGridClass;
    if (photos.length > 0) {
        itemGridClass = 'gallery__item_1x3'
    }
    if (photos.length > (3 - props.renderQr)) {
        itemGridClass = 'gallery__item_2x3';
    }
    if (photos.length > (6 - props.renderQr)) {
        itemGridClass = 'gallery__item_3x3';
    }
    const bannerText = getConfigParam('bannerText');
    let banner = null;
    if (bannerText) {
        banner = (<>
            <div className="banner__offer">
                <div className="banner__content">
                    <p className="banner_custom_text">{bannerText}</p>
                </div>
            </div>
        </>);
    } else {
        banner = (<>{props.offers.map((offer, o) =>
            <div key={o} className="banner__offer">
                {offer.claim_text && <div className="banner__claim">
                    <span>{offer.claim_text}</span>
                </div>}
                <div className="banner__content">
                    <p className="banner__text">{offer.text}</p>
                    {offer.unit_price_text && <p className="banner__unit">
                        {offer.unit_price_text}
                    </p>}
                    <div className="banner__notice">
                        {offer.notice_text.map((item, i) =>
                            <span key={i} className="banner__notice-text">{item}</span>
                        )}
                    </div>
                </div>
            </div>
        )}</>);
    }

    return (<>
            {(photos.length === 0 || props.error) ?
                <div className="loading">
                    <p className="loading__text">
                        {props.error ? "Error al cargar las fotos" : "Cargando..."}
                    </p>
                </div> :
                <main className="app" ref={galleryRef}>
                    <div className="gallery">
                        {photos.map((item, i) => {
                            let mediaContent, overlayUrl;
                            if (item.is_video) {
                                overlayUrl = item.thumbnail_url;
                                mediaContent = (
                                    <video key={item.public_url}
                                           className="gallery__img"
                                           title={item.album_name}
                                           autoPlay loop muted
                                           poster={removeWatermark ? item.cover_url : item.thumbnail_url}>
                                        <source src={item.public_url}></source>
                                    </video>
                                );
                            } else {
                                overlayUrl = item.public_url;
                                mediaContent = (
                                    <img className="gallery__img"
                                         src={removeWatermark ? item.cover_url : item.public_url}
                                         alt={item.album_name}/>);
                            }
                            return (
                                <div key={i}
                                     className={`gallery__item ${itemGridClass}  gallery__image ${props.renderSlide ? 'out' : 'in'}`}>
                                    <div className="gallery__overlay">
                                        <img className="gallery__overlay-img" src={overlayUrl} alt={item.album_name}/>
                                    </div>
                                    <figure className="gallery__figure">
                                        {mediaContent}
                                    </figure>
                                </div>
                            )
                        })}
                        {props.renderQr &&
                            <div
                                className={`gallery__item ${itemGridClass} gallery__cta ${props.renderSlide ? 'out' : 'in'}`}>
                                <div className="cta">
                                    <h1 className="cta__title">
                                        Consigue tus fotos
                                    </h1>
                                    <p className="cta__instructions">
                                        Escanea AHORA el código
                                    </p>
                                    <div className="cta__qr">
                                        <QRCodeSVG value={api.QR_URL(props.uuid)} alt="Código QR"
                                                   className="cta__qr-img"/>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="banner">
                        {banner}
                    </div>
                </main>
            }
        </>
    )
}

const toggleFullScreen = (element) => {
    if (!document.fullscreenElement) {
        element.requestFullscreen();
    } else {
        document.exitFullscreen();
    }
}

const mapStateToProps = state => ({
    error: state.error,
    isFetching: state.isFetching,
    offers: state.offers,
    photos: state.photos,
    slide: state.slide,
    numSlides: state.numSlides,
    mediasPerSlide: state.mediasPerSlide,
    renderQr: state.renderQr,
    renderSlide: state.renderSlide
})

export default connect(mapStateToProps)(App);