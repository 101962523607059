import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import 'core-js/es/map';
import 'core-js/es/set';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';

import 'raf/polyfill';

import React from 'react';
import {Provider} from 'react-redux';
import rootReducer from './reducer';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {configureStore} from "@reduxjs/toolkit";
import {logger} from "redux-logger/src";
import {getConfigParam} from "./utils";
import {options} from "./config";


const token = getConfigParam('token');
const uuid = getConfigParam('uuid');

if (!uuid || !token) {
    ReactDOM.render(<React.StrictMode>
            <div className="loading">
                <p className="loading__text">
                    Introduce los parámetros necesarios.
                </p>
            </div>
        </React.StrictMode>,
        document.getElementById('root')
    )
} else {
    let storeConfiguration = {
        reducer: rootReducer,
    };
    if (options.environment !== "prod") {
        storeConfiguration.middleware = (getDefaultMiddleware) => getDefaultMiddleware().concat(logger);
    }

    const store = configureStore(storeConfiguration);
    ReactDOM.render(
        <React.StrictMode>
            <Provider store={store}>
                <App token={token} uuid={uuid}/>
            </Provider>
        </React.StrictMode>,
        document.getElementById('root')
    );
}
