import { actionTypes } from './actions';

const initState = {
  lastUpdated: + new Date(),
  renderSlide: false,
  slide: 0,
  numSlides: 0,
  photos: [],
  offers: [{
    claim_text: '',
    notice_text: [''],
    text: ''
  }]
}

function rootReducer(state = initState, action) {
  switch(action.type) {
    case actionTypes.SHOW_SLIDE:
      return {
        ...state,
        renderSlide: true,
      }
    case actionTypes.CHANGE_SLIDE:
      return {
        ...state,
        slide: action.payload.nextSlide,
        renderSlide: false,
        error: null
      }
    case actionTypes.REQUEST_PHOTOS:
      return {
        ...state,
        isFetching: true,
      }
    case actionTypes.RECEIVE_PHOTOS:
      let numSlides = Math.ceil(action.payload.photos.length / action.payload.mediasPerSlide);
      return {
        ...state,
        isFetching: false,
        numSlides: numSlides,
        mediasPerSlide: action.payload.mediasPerSlide,
        renderQr: action.payload.renderQr,
        photos: action.payload.photos,
        offers: action.payload.data.result.offers || [],
      }
    case actionTypes.ERROR_PHOTOS:
      return {
        ...state,
        error: action.error,
      }
    default:
      return {
        ...state
      }
  }
}

export default rootReducer
