import {api, options} from './config';
import {getBooleanConfigParam, getConfigParam} from './utils';

export const actionTypes = {
    CHANGE_SLIDE: 'CHANGE_SLIDE',
    SHOW_SLIDE: 'SHOW_SLIDE',
    REQUEST_PHOTOS: 'REQUEST_PHOTOS',
    RECEIVE_PHOTOS: 'RECEIVE_PHOTOS',
    ERROR_PHOTOS: 'ERROR_PHOTOS'
};

function showSlide() {
    return {
        type: actionTypes.SHOW_SLIDE
    }
}

function changeSlide(nextSlide) {
    return {
        type: actionTypes.CHANGE_SLIDE,
        payload: {
            nextSlide: nextSlide,
        }
    }
}

function requestPhotos() {
    return {
        type: actionTypes.REQUEST_PHOTOS
    }
}

function receivePhotos(firstResponse, responseBatch, mediasPerSlide, renderQr) {
    return {
        type: actionTypes.RECEIVE_PHOTOS,
        payload: {
            data: firstResponse,
            photos: responseBatch,
            mediasPerSlide: mediasPerSlide,
            renderQr: renderQr,
        }
    }
}

function errorPhotos(err) {
    return {
        type: actionTypes.ERROR_PHOTOS,
        error: err
    }
}

function shouldFetchPhotos(state) {
    const {isFetching, slide, numSlides} = state;

    if (slide === numSlides) {
        return true
    } else if (isFetching) {
        return false
    } else {
        return false
    }
}

async function doFetch(dispatch, getState, hostUuid, token, pages, albumName, mediasPerSlide, renderQr) {
    dispatch(requestPhotos())

    let albumId = null;
    const fetchConfig = {
        headers: {
            Authorization: `Bearer ${token}`,
            "x-api-client-id": options.apiClientId,
            "x-api-client-secret": options.apiClientSecret,
        }
    }
    if (albumName) {
        let result = await fetch(api.ALBUM_URL(hostUuid, albumName), fetchConfig)
        let json = await result.json();
        if (json.result.elements.length > 0) {
            albumId = json.result.elements[0].id;
        }else{
            return dispatch(errorPhotos("Album not found"));
        }
    }

    const promiseArray = [];
    for (let p = 0; p < pages; p++) {
        promiseArray.push(fetch(api.BASE_URL(hostUuid, albumId, p + 1), fetchConfig))
    }

    Promise.all(promiseArray).then(res => {
        const responseArray = [];
        for (let r of res) {
            responseArray.push(r.json())
        }
        Promise.all(responseArray).then(json => {
            let photoArray = [];
            const meta = json[0];
            json.map(j => photoArray.push(...j.result.photos))
                dispatch(receivePhotos(meta, photoArray, mediasPerSlide, renderQr))
                setTimeout(() => {
                    dispatch(changeSlide(1));
                }, options.fadeInOutInterval);
                dispatch(showSlide());
        })
    }).catch(err => dispatch(errorPhotos(err)))
}

export function swipeAndFetchIfNeeded(uuid, token) {
    const albumName = getConfigParam('albumName');
    const pages = getConfigParam('pages');
    const renderQr = getBooleanConfigParam('renderQr');
    let mediasPerSlide = getConfigParam('mediasPerSlide');
    if (renderQr && mediasPerSlide % 3 === 0) {
        mediasPerSlide -= 1;
    }
    return (dispatch, getState) => {
        if (shouldFetchPhotos(getState())) {
            doFetch(dispatch, getState, uuid, token, pages, albumName, mediasPerSlide, renderQr)
        } else {
            setTimeout(() => {
                dispatch(changeSlide((getState()).slide + 1))
            }, options.fadeInOutInterval);
            dispatch(showSlide());
        }
    }
}