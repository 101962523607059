import { options } from './config';

export function getNumberConfigParam(param) {
  const searchParams = (new URL(document.location)).searchParams;

  if (searchParams.get(param)) {
    return Number(searchParams.get(param))
  } else if (options[param]) {
    return Number(options[param]);
  }

  return null;
}

export function getBooleanConfigParam(param) {
  const searchParams = (new URL(document.location)).searchParams;

  if (searchParams.get(param)) {
    return searchParams.get(param) === true || searchParams.get(param) === "true";
  } else if (options[param]) {
    return options[param];
  }

  return null;
}

export function getConfigParam(param) {
  const searchParams = (new URL(document.location)).searchParams;

  if (searchParams.get(param)) {
    return searchParams.get(param)
  } else if (options[param]) {
    return options[param];
  }

  return null;
}
