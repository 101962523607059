export const api = {
  BASE_URL: (galleryUuid, albumId, p) => `https://${process.env.REACT_APP_API_DOMAIN}/api/v2/photos?gallery_uuid=${galleryUuid}&album_id=${albumId}&order_created_at=DESC&_locale=es-es&page=${p}&limit=${options.limit}`,
  ALBUM_URL: (id, name) => `https://${process.env.REACT_APP_API_DOMAIN}/api/v2/albums?businessHostUuidFilter=${id}&albumNameFilter=${name}`,
  QR_URL: (uuid) => `https://${process.env.REACT_APP_API_DOMAIN}/default/profile/business-host/${uuid}#albums`,
}
export const options = {
  pages: 3,
  limit: 10,
  mediasPerSlide: 6,
  fsKeyCode: 70,
  slideInterval: 7e3,
  fadeInOutInterval: 1000,
  renderQr: true,
  removeWatermark: false,
  environment: process.env.REACT_APP_ENVIRONMENT,
  apiClientId: process.env.REACT_APP_API_CLIENT_ID,
  apiClientSecret: process.env.REACT_APP_API_CLIENT_SECRET,
}
